.wishlist-container{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow-x:auto;
    overflow-y:hidden;
}

.wishlist .container{ 
    min-width: 90rem;
}

.wishlist .title{
    background-color: var(--secondary-color);
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem 0.5rem;
}

.wishlist .title h3{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    font-size: 1.8rem;
    text-align: center;
}

.wishlist .box-container{
    padding: 1rem;
}

