
.about .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 2rem;
}


.about .video-container{
    -webkit-box-flex: 1;
    -ms-flex:1 1 40rem;
        flex:1 1 40rem;
    position: relative;
}

.about .video-container video{
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    border: 1.5rem solid var(--white);
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.about .video-container h3{
    position: absolute;
    bottom: 1rem;
    right: 0;
    font-size: 3rem;
    background:#fff;
    width:50%;
    padding:1rem 2rem;
    text-align: center;
    mix-blend-mode: screen;
    border-radius: var(--border-radius);
}

.about .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}
 
.about .content h2{
    color: var(--black);
    font-size: 4rem;
    letter-spacing: 0.1rem;
    margin-bottom: 2rem;
}

.about .content h5{
    color: var(--black);
    font-size: 1.8rem;
    line-height: 1.5;
    padding-bottom: 1.5rem;
}

.about .content > p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.6;
    padding-bottom: 1rem;
}
