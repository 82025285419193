.scroll-top{
  position: fixed;
  bottom: 1rem;
  right:1rem;
  height: 5rem;
  width: 5rem;
  font-size: 2rem;
  font-weight: 600;
  color: var(--main-color);
  background: var(--white);
  border: 0.2rem solid var(--main-color);
  border-radius: 50%; 
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.scroll-top:hover{
  background: var(--main-color);
  border-color: var(--white);
  cursor: pointer;
}

.scroll-top svg{
  fill:  var(--main-color);
}

.scroll-top:hover svg{
  fill: var(--white)
}