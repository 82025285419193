.shop{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
}

.shop .shop-container{    
    -webkit-box-flex: 1;
    -ms-flex: 1 1 66rem;
        flex: 1 1 66rem;
}

.shop .product-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.shop .product-container.grid{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 1rem;
}