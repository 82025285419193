.change-pwd form{
    max-width: 40rem;
}

.change-pwd .btn{
    width: 100%;
}

.change-pwd form .link{
    font-size: 1.5rem;
    color: var(--secondary-color);
}

.change-pwd form .link:hover{
    text-decoration: underline;
}