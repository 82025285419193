.blog-details{
    display: -webkit-box;  
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 2rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.blog-details .blog-container{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 66rem;
        flex: 1 1 66rem;
}
 