.shop-sidebar{
    -webkit-box-flex: 1;
    -ms-flex:1 1 25rem;
        flex:1 1 25rem;
} 

.shop-sidebar .sidebar-item{
    background-color: var(--white);;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: var(--border-radius);
}

.shop-sidebar .box-container{
    padding: 1rem 0;
}

/*-- 1- Category --*/
.shop-sidebar .category .item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 0;
}

.shop-sidebar .category .item a{
    font-size: 2rem;
    color: var(--black);
    font-weight: 400;
    cursor: pointer;
}

.shop-sidebar .category .item a:hover{
    color: var(--secondary-color);
}

.shop-sidebar .category .item p{
    font-size: 1.8rem;
    color: var(--main-color);
}

/*-- 3- Popular Products --*/
.shop-sidebar .product-item .options{
    display: none;
}

.shop-sidebar .product-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-bottom: 1rem;
    text-align: left;
}

.shop-sidebar .product-item .image img{
    height: 12rem;
    width: 10rem;
    background-color: var(--grey);
    border-radius: var(--border-radius);
}

.shop-sidebar .product-item h3{
    font-size: 2rem;
}

.shop-sidebar .product-item .rating{
    font-size: 1.7rem;
}

.shop-sidebar .product-item .price{
    font-size: 1.7rem;
}

.shop-sidebar .product-item .price span{
    font-size: 1.3rem;
}
