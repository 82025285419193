.shop-summary{
    background-color: var(--white);
}

.summary-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center;
    margin: 1rem 0;
    padding: 0.5rem 0;
}

.summary-item .box{
    font-size: 2rem;
    font-weight: bold;
    color: var(--black);
}

.summary-item .value{
    font-weight: 500;
    color: var(--secondary-color);
}