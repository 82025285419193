.profile .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
} 

.profile-item{
    background-color: var(--white);;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    padding: 2rem;
    border-radius: 0.5rem;
}

.profile .profile-details{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 35rem;
        flex: 1 1 35rem;
}

.profile .profile-details .content{
    text-align: center;
}

.profile .profile-details .content img{
    height: 15rem;
    width: 15rem;
    border: 0.1rem solid var(--black);
    border-radius: 50%;
}

.profile .profile-details .content h3{
    padding: 1rem 0 0.5rem 0;
    font-size: 2rem;
    color: var(--black);
}

.profile .profile-details .content p{
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--grey);
}

.profile .profile-details .content .gmail{
    text-transform: none;
}

.profile .address-details{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50rem;
        flex: 1 1 50rem;
    background-color: var(--white);;
    padding: 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.profile .address-details .address-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1.5rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.profile .btn{
    margin-top: 1rem;
}
