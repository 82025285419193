.contact{
    padding: 0;
}

.contact iframe{
    width: 100%;
    height: 30rem;
}
 
.contact-info{
    background-color: var(--secondary-color);
    padding: 2rem 5%;
}

.contact-info .heading h1{
    color: var(--white) !important;
}

.contact-info .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.contact-info .info-item{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem;
        flex: 1 1 25rem;
    padding: 1rem 0;
    text-align: center;
}

.contact-info .info-item .icon-item{
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0rem auto;
}

.contact-info .info-item .icon{
    font-size: 2.5rem;
    color: var(--main-color);
}

.contact-info .info-item .content{
    padding-left: 1rem;
}

.contact-info .info-item .content h4{
    font-size: 2.2rem;
    font-weight: 500;
    color: var(--white);
    padding: 1rem 0;
}

.contact-info .info-item .content p{
    font-size: 1.6rem;
    color: var(--white);
    line-height: 1.6;
    text-transform: none;
}

.contact-form{
    background-color: var(--white);
    border-radius: 1rem;
    padding: 2rem;
}

.contact-form h3{
    font-size: 2rem;
    color: var(--black);
}

.contact-form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.contact-form .box{
    width: 100%;
    text-transform: none;
    color: var(--grey);
    font-size: 1.6rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding: 1.5rem 1rem;
}

.contact-form .box:focus{
    border-color: var(--secondary-color);
}

.contact-form .box::placeholder{
    color: var(--grey);
    text-transform: capitalize;
}

.contact-form textarea{
    height: 20rem;
    resize: none;
}

.contact-form .btn{
    margin-top: 0rem;
}

.contact .msg-alert{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.contact .alert{
    display: inline-block;
    font-size: 2rem;
    color: var(--main-color);
    padding-left: 1rem;
}