.heading{
  padding-top: 1rem;
}
  
.heading h1 {
    text-transform: capitalize;
    text-align:center;
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: var(--white);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    margin-bottom: 2rem;
    color: var(--black);
  }

  .heading h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: var(--main-color);
    left:50%; margin-left:-30px;
  }
  
  .heading h1 span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 3em;
    padding-left: 0.25em;
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 10px;
  }
