.btn{
    display: inline-block;
    color: var(--white);
    font-size: 1.6rem;
    font-weight: bolder;
    padding: 1.5rem 3rem;
    position: relative; 
    overflow: hidden;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
	background-color: var(--main-color);
}

.btn::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 100%;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateX(-100%) rotate(45deg);
            transform: translateX(-100%) rotate(45deg);
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.btn:hover{
    background-color: var(--secondary-color);
	cursor: pointer;
}

.btn:hover::before{
    -webkit-transform: translateX(100%) rotate(45deg);
            transform: translateX(100%) rotate(45deg);
}
