@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&family=Neonderthaw&family=Nunito:wght@600;700&family=Permanent+Marker&family=Raleway:wght@100;300;400;500;600;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,900&display=swap");

/* ---------- GLOBAL (start) ---------- */
  
:root {
  --black: #180A0A;
  --white: #fff;
  --grey: var(--grey);;
  --main-color: #F10086;
  --secondary-color: #F582A7;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  --border-radius: 1rem;
}

* {
  /* font-family: "Nunito", sans-serif; */
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;


  outline: none;
  border: none;

  text-transform: capitalize;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  text-decoration: none;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 7rem;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 0.8rem;
}

html::-webkit-scrollbar-track {
  background: whitesmoke;
}

html::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: var(--secondary-color);
}

body {
  background: whitesmoke;
}

section {
  padding: 2rem 5%;
}

/*----- Form CSS -----*/
.form{
  padding: 2rem;
  background: var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  margin: 1rem auto;
  text-align: center;
  border: 0.2rem solid var(--main-color);
  border-radius: var(--border-radius);
}

.form h3 { 
  font-size: 2.5rem;
  padding-bottom: 1rem;
  color: #333;
  text-transform: uppercase;
  text-align: center;
}

.form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.form .input-box .box{
    width: 49%;
}

.form .box {
    width: 100%;
  padding: 1.2rem 0.8rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  font-size: 1.6rem;
  color: var(--grey);;
  text-transform: none;
  margin: .7rem 0;
}

.form .box::placeholder{
    text-transform: capitalize;
}

.form .box:focus{
  border-color: var(--secondary-color);
}

.form p {
  padding-top: 2rem;
  font-size: 1.5rem;
  color: var(--grey);;
  text-align: center;
  padding-bottom: 1rem;
}

.form button{
  width: 100%;
  margin: 1rem 0;
}

.form button .btn{
  width: 100%;
}


/*--------------------- Responsive (Start) ---------------------*/
@media (max-width: 991px){
  html{
      font-size: 55%;
  }

  .blog.grid, .blog.list{
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
}

}

@media (max-width: 768px){

  section, header{
      padding: 2rem;
  } 

  .shop{
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .testimonial-slider .swiper-button-next{
    right: -6rem;
  }

  .testimonial-slider .swiper-button-prev{
      left: -6rem;
  }

  .testimonial-slider{
    padding: 0rem 7rem;
  }

}

@media (max-width: 450px){
  html{
      font-size: 50%;
  }

  section, header{
      padding: 1rem;
  }

  .contact-form .input-box{
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .form .input-box .box{
    width: 100%;
  }

  .cart-total{
    width: 100%;
  }

  .checkout .address form .input-box .box{
    width: 100%;
  }

  .leave-reply form .input-box .box{
    width: 100%;
  }

  .edit-address form .box {
    width: 100%;
  }

}

/*--------------------- Responsive (End) ---------------------*/
