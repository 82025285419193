.blog-info{
    background-color: var(--white);;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.blog-info .date{
    position: absolute;
    top: 0rem;
    left: 0rem;
    color: var(--white);
    background-color: var(--secondary-color);
    opacity: 0.9;
    font-size: 2rem;
    padding: 2rem;
    text-align: center;
}

.blog-info .image{
  height: 40rem;
}

.blog-info .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-info .content{
    padding: 2rem;   
}

.blog-info .content .main-heading{
    display: inline-block;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--black);
    padding-bottom: 1rem;
  }

.blog-info .content .details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 2rem;
} 

.blog-info .content .details h5{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--grey);
    padding: 0.5rem 1rem;
}

.blog-info .content .details h5:first-child{
    padding-left: 0;
}

.blog-info .content .details h5 span{
  padding-left: 0.5rem;
}

.blog-info .content .details h5 .icon{
    color: var(--secondary-color);
  }

.blog-info p{
  padding-bottom: 2rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}

.blog-info .important{
    padding: 2rem 4rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--black);
    font-style: italic;
    line-height: 1.8;
    border-left: 0.2rem solid var(--main-color);
    margin-bottom: 2rem;
    margin-left: 1rem;
}

.blog-info .blog-gallery{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 0.5rem;
    padding: 2rem 0; 
}

.blog-info .blog-gallery img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.blog-info .end-details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-info .tags, 
.blog-info .share{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-info .tags h3,
.blog-info .share h3{
    color: var(--black);
    font-size: 1.8rem;
    padding-right: 1rem;
}

.blog-info .tags span,
.blog-info .share .icon{
    margin: 0 0.5rem;
    color: var(--grey);
    font-size: 1.6rem;
}

.blog-info .share .icon:hover{
    color: var(--secondary-color);
    cursor: pointer;
}
