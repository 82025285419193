.order-details .order-intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 2rem; 
    margin-bottom: 2rem;
}

/*----- 1- Order Summary -----*/
.order-details .order-summary{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 37rem;
        flex: 1 1 37rem;
    background-color: var(--white);
    padding: 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}

.order-details .order-summary .intro{
    border-bottom: 0.2rem solid var(--grey);
}

.order-details .intro .order-id{
    font-size: 2rem;
    font-weight: bold;
    color: var(--black);
    padding-bottom: 1rem;
}

.order-details .intro .delivery{
    color: var(--grey);
    font-size: 1.8rem;
    padding-bottom: 1rem;
}

 /*----- 2- Address Details -----*/
 .address-details{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50rem;
        flex: 1 1 50rem;
    background-color: var(--white);
    padding: 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}

.address-details .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1.5rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


/*----- 3- Order Items -----*/
.order-details .order-items{
    background-color: var(--white);
    padding: 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    overflow-x: auto;
    overflow-y: hidden; 
    border-radius: var(--border-radius);
}

.order-details .order-items .container{
    min-width: 90rem;
}

.order-items .title{
    background-color: var(--secondary-color);
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem 0.5rem;
    margin-bottom: 2rem;
    border-radius: var(--border-radius);
}

.order-items .title h3{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    font-size: 1.8rem;
    text-align: center;
}