.team .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1rem;
  } 
  
.team-item {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.team-item .image {
  border: 0.5rem solid var(--white);
  width: 80%;
  border-radius: 50%;
  overflow: hidden;
}

.team-item:hover .image{
  border: 0.5rem solid var(--main-color);
}

.team-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-item:hover .images img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
  
.team-item .content h3 {
    font-size: 2.2rem;
    font-weight: 500;
    padding-top: 0.4rem;
  }

.team-item .content p{
  font-size: 1.6rem;
  padding: 0.5rem 0;
  color: var(--main-color);
}
  
.team-item .content .icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
  
.team-item .icon-container a {
  height: 4rem;
  width: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--main-color);
  border: 0.1rem solid var(--main-color);
  font-size: 1.5rem;
  border-radius: 50%;
}

.team-item .icon-container a:hover{
  color: var(--white);
  background-color: var(--main-color);
}
  