.product-item{
    position: relative;
    text-align: center;
    overflow: hidden;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease; 
    transition: all 1s ease;
    background-color: var(--white);;
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.product-item .options{
    position: absolute;
    top: 2%;
    left: -100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product-item .options .icon{
    width: 4rem;
    height: 4rem;
    background-color: var(--main-color);
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--white);;
    cursor: pointer;
}

.product-item .options .icon:hover{
    background-color: var(--black);
}

.product-item .image img{
    width: 100%;
    height: 25rem;
    object-fit: cover;
}

.product-item h3{
    font-size: 2.5rem;
    font-weight: 500;
    padding-top: 1rem;
    color: var(--black);
}

.product-item .rating{
    font-size: 2rem;
    color: gold;
    padding: 0.7rem 0;
}

.product-item .price{
    font-size: 2rem;
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 2rem;
}

.product-item .price span{ 
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: line-through;
    color: var(--grey);
    padding-left: 0.2rem;
}

.product-item:hover .options{
    left: 2%;
}
