.blog-item{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background-color: var(--white);
  overflow: hidden; 
  border-radius: var(--border-radius);
}

.blog-item .image{
  height: 25rem;
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.blog-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-item .content{
  padding: 2rem 1rem;
}

.blog-item .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--black);
}

.blog-item .content .main-heading:hover{
  color: var(--secondary-color);
}

.blog-item .content p{
  padding-top: 1rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}